<template>
  <navbar />

  <main
    class="prose-headings:cgn-heading prose-h1:cgn-heading-1 prose-h2:cgn-heading-2 prose-h3:cgn-heading-3 min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 pb-10 text-gray-800 dark:from-darkbg-500 dark:to-darkbg-800 dark:text-white"
  >
    <cgn-page-router />
  </main>

  <Footer />
</template>

<script setup lang="ts">
</script>
