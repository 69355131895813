<template>
  <footer aria-labelledby="footer-heading" class="bg-secondary-500 shadow-2xl">
    <div id="footer-heading" class="sr-only">
      Footer
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="pb-6 pt-10">
        <div class="grid w-full grid-cols-2 md:grid-cols-3 lg:flex">
          <div v-for="menu in menus" :key="menu.id" class="flex grow flex-col">
            <span class="pb-2 text-lg font-semibold uppercase text-brand-500">{{ menu.name }}</span>
            <div class="prose" v-html="menu.content" />
            <div class="flex w-fit flex-col divide-y divide-gray-100">
              <div v-for="link in menu.children" :key="link.id" class="py-2">
                <router-link
                  :to="link.link"
                  class="px-2 text-on-secondary hover:text-secondary-200"
                >
                  {{ link.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary-700 py-6 text-center shadow-md">
      <p class="text-white">
        &copy; {{ year }}. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const year = ref(new Date().getFullYear())
const menus = ref<CognitoMenu[]>([])

const loadMenu = async () => {
  menus.value = await new CognitoMenu().getMenu('Footer Menu', '16x3', '1920')
}

onMounted(() => {
  loadMenu()
})

onServerPrefetch(async () => {
  await loadMenu()
})
</script>
